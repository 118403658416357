<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ getProjectTypeName(project.projectTypeId) }}</h1>
        </div>
        <div class="box-main">
          <Steps :page-options="pageOptions" :project="project" />
          <div class="section list-view">
            <div class="head">
              <h5>PASSO 2</h5>
              <h4>Abrangência</h4>
            </div>
            <v-layout w-100>
              <gmap-autocomplete @place_changed="setPlace" placeholder="Começe a digitar o nome de uma cidade ou país"
                :options="autocompleteOptions" :selectFirstOnEnter="true" ref="gmap"
                class="form-input input-autocomplete"></gmap-autocomplete>
              <v-btn flat round color="white" class="btn-primary flex-inherit px-5" large v-on:click="addCoverage()"
                :disabled="this.coverageModel.name == ''">Adicionar</v-btn>
              <v-flex justify-space-between d-flex>
                <label v-if="showCoverageValidationMessage" class="d-flex justify-end msg-validation"
                  :key="showCoverageValidationMessage">
                  Adicione ao menos uma abrangência
                </label>
              </v-flex>
            </v-layout>
            <div class="wrapper" v-for="cover in project.coverageList" :key="cover.Order">
              <div class="list-item">
                <div>{{ cover.name }}</div>
                <div class="action-bar">
                  <a v-on:click="openConfirmRemoveCoverageModal(cover)">
                    <i class="fal fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="section card-grid">
              <v-layout head class="d-block">
                <div class="d-flex mb-2">
                  <h3>Produtos de Doação</h3>
                  <v-btn flat round color="white" class="ml-auto btn-primary px-5 m-l-auto t-transf-none" large
                    @click="newDonationProduct">
                    <i class="mr-2 far fa-plus"></i>Novo Produto
                  </v-btn>
                </div>
                <BasicAlert type="warning" strong>
                  <strong class="mr-1">Atenção:</strong>
                  Os produtos de doação somente serão oferecidos em campanhas que
                  estejam configuradas para utilizar a estrutura de produtos legada.
                </BasicAlert>
              </v-layout>
              <div class="wrapper">
                <template v-for="item in project.donationProductList">
                  <div v-bind:key="item.Order" class="card-item">
                    <FileImage :file="item.file" :alt="''" />
                    <h5>{{ item.name }}</h5>
                    <span>{{ item.suggestionValue }}</span>
                    <p>{{ item.description }}</p>
                    <div class="display-flex action-card">
                      <a @click="editDonationProduct(item)" class="edit-option">
                        <Icon :name="'fal fa-pen'" :size="16" />
                      </a>
                      <a v-if="item.active" @click="openConfirmDisableDonationProductModal(item)" class="delete-option"
                        title="Inativar Produto">
                        <Icon :name="'fal fa-ban'" :size="16" />
                      </a>
                      <a v-else @click="activeDonationProduct(item)" class="delete-option" title="Ativar Produto">
                        <Icon :name="'fal fa-check'" :size="16" />
                      </a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="text" />
    </v-layout>
    <FooterStep v-if="!isTicket" v-model="pageOptions" :validationMethod="isValid" :project="project" />
    <FooterStep v-else v-model="pageOptions" :validationMethod="isValid" nextLabelText="Concluir" :project="project"
      @clickNext="pageOptions.currentPage += 4" />
    <ValueRange ref="valueRangeComponent" v-model="project.donationProductList" :projectId="project.id"
      :isTicket="isTicket"></ValueRange>
    <AucAlert ref="coverageAlertComponent" title="Você tem certeza que deseja excluir essa abrangência?"
      confirmButtonText="Sim" cancelButtonText="Não" @confirm="removeCoverage(selectedCoverage)" />
    <AucAlert ref="donationProductAlertComponent" title="Você tem certeza que deseja desativar esse produto de doação?"
      confirmButtonText="Sim" cancelButtonText="Não" @confirm="disableDonationProduct(selectedDonationProduct)" />
  </div>
</template>
<script>
import FooterStep from "@/components/FooterStep.vue";
import HelpBox from "@/components/HelpBox.vue";
import ValueRange from "@/components/ValueRange.vue";
import FileImage from "@/components/FileImage.vue";
import Coverage from "@/scripts/models/coverage.model.js";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import {
  ProjectTypes,
  GetProjectTypeName,
} from "@/scripts/models/enums/projectTypes.enum.js";
import DonationProduct from "@/scripts/models/donationProduct.model";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import AucAlert from "@/components/AucAlert.vue";
import BasicAlert from "@/components/BasicAlert.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import FileService from "@/scripts/services/file.service";
export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    FooterStep,
    ValueRange,
    FileImage,
    AucAlert,
    BasicAlert
  },
  props: ["pageOptions"],
  data() {
    return {
      text: HelpBoxText.ProjectStep2,
      project: new Project(),
      projectService: new ProjectService(),
      coverageModel: new Coverage(),
      autocompleteOptions: {
        types: ['(cities)'],
        componentRestrictions: { country: "br" },
      },
      getProjectTypeName: GetProjectTypeName,
      newProduct: false,
      projectValidationStatusIndex: 2,
      showCoverageValidationMessage: false,
      selectedCoverage: null,
      textarea: "",
      showFieldProduct: false,
      dialog: false,
      selectedDonationProduct: null,
      fileService: new FileService(),
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { text: "Passo 2" },
      ],
    };
  },
  beforeRouteEnter: (to, _, next) => {
    if (!to.query.projectId || to.query.projectId == 0) return next({ name: Routes.app.ProjectStep0 });

    const projectService = new ProjectService();
    projectService.findById(to.query.projectId).then(function (data) {
      to.query.project = data;
      next();
    });
  },
  beforeRouteLeave(to, _, next) {
    if (!this.$route.query.saved) {
      this.$route.query.saved = true;
      this.projectService.save(this.project).then(next);
    } else {
      next();
    }
  },
  created() {
    this.project = this.$route.query.project;
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.pageOptions.changeClass("footer");
    this.pageOptions.currentPage = 2;
    this.validationMethod = this.validate;
    this.onValidationSuccessMethod = this.setValidStep;
    this.onValidationFailMethod = this.setInvalidStep;
  },
  computed: {
    isTicket() {
      return this.project.projectTypeId == ProjectTypes.Ticket;
    },
  },
  methods: {
    openConfirmRemoveCoverageModal(item) {
      this.selectedCoverage = item;
      this.$refs.coverageAlertComponent.open();
    },
    openConfirmDisableDonationProductModal(item) {
      this.selectedDonationProduct = item;
      this.$refs.donationProductAlertComponent.open();
    },
    activeDonationProduct(item) {
      this.$refs.valueRangeComponent.changeStatus(item, true);
      this.validateStep();
    },
    disableDonationProduct(item) {
      this.$refs.valueRangeComponent.changeStatus(item, false);
      this.validateStep();
    },
    editDonationProduct(item) {
      this.newProduct = false;
      this.$refs.valueRangeComponent.open(item);
    },
    newDonationProduct() {
      this.newProduct = true;
      this.$refs.valueRangeComponent.open(new DonationProduct());
    },
    setPlace: function (locationData) {
      this.coverageModel.name = locationData.formatted_address.replace(/, [0-9]{5}-[0-9]{3}/, '');
      this.coverageModel.lat = locationData.geometry.location.lat();
      this.coverageModel.long = locationData.geometry.location.lng();
    },
    getAddressData: function (addressData) {
      this.address = addressData;
    },
    listCoverageByProjectId() {
      this.projectService.listCoverageByProjectId(this.project.id).then(
        function (data) {
          this.project.coverageList = data;
          this.validateStep();
          if (this.project.coverageList.length == 0) {
            this.showCoverageValidationMessage = true;
          }
        }.bind(this)
      );
    },
    addCoverage() {
      this.coverageModel.projectId = this.project.id;

      const copyCoverage = { ...this.coverageModel, name: this.$refs.gmap.$el.value }

      this.projectService.saveCoverage(
        this.refreshCoverageList,
        copyCoverage
      );
      this.coverageModel = new Coverage();
      this.$refs.gmap.$el.value = null;
    },
    removeCoverage(item) {
      this.projectService.deleteCoverage(this.refreshCoverageList, item.id);
    },
    refreshCoverageList() {
      this.listCoverageByProjectId();
    },
    setValidStep() {
      this.$set(this.project.stepsValidationStatus, "step2", true);
    },
    showFailMessage() {
      if (this.project.coverageList.length) return;
      this.$store.commit(
        "SET_SNACKBAR_MESSAGE",
        "Você não preencheu todos os campos"
      );
    },
    setInvalidStep() {
      this.$set(this.project.stepsValidationStatus, "step2", false);
    },
    validateStep() {
      const valid = this.isValid(false);

      if (valid) this.setValidStep();
      else this.setInvalidStep();

      this.projectService.save(this.project);
    },
    validate(performMethods) {
      let valid = true;

      this.showCoverageValidationMessage = false;

      if (!this.project.coverageList || this.project.coverageList.length == 0) {
        valid = false;
        if (performMethods) this.showCoverageValidationMessage = true;
      }

      if (!valid && performMethods) this.showFailMessage();

      return valid;
    },
    getImage(index) {
      if (this.validURL(this.project.donationProductList[index].file.path)) return this.project.donationProductList[index].file.path;

      this.fileService
        .render(this.project.donationProductList[index].file.id)
        .then(
          function (data) {
            this.project.donationProductList[index].file.path = data;
            return data;
          }.bind(this)
        );
    },
    validURL(str) {
      const regex =
        /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      return regex.test(str)
    },
  },
};
</script>