<template>
    <div class="alert" :class="[className, bold]">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        strong: Boolean
    },
    computed: {
        className() {
            switch (this.type) {
                case 'success':
                    return 'alert-success';
                case 'error':
                    return 'alert-danger';
                case 'warning':
                    return 'alert-warning';
                default:
                    return 'alert-info';
            }
        },
        bold() {
            return this.strong ? 'bold' : '';
        }
    }
};
</script>